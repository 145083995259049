
ul.icons {
	cursor: default;
	list-style: none;
	padding-left: 0;

	li {
		display: inline-block;
		padding: 0 1em 0 0;

		&:last-child {
			padding-right: 0 !important;
		}
	}

  &.bulleted {
    li {
      padding-left: 2em;
      position: relative;
      display: block;

      &.icon {
        &:before {
          left: 0;
          position: absolute;
          top: 0;
        }
      }

      > :last-child {
        margin-bottom: 1em;
      }
    }
  }

}
