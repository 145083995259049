.pagehead {
  height: 20em !important;
  min-height: 15em !important;
}
.page-section-smaller {
  padding: 2rem 0;
}
.entry-content h2 {
    padding-bottom: 10px;
    position: relative;
    margin-bottom: 30px;
}

.entry-content h2:after {
    background: #f4623a;
    content: "";
    left: 0;
    bottom: 0px;
    width: 80px;
    height: 3px;
    position: absolute;
}

.entry-content h3 {
    margin-bottom: 20px;
}

.entry-content p {
    margin-bottom: 25px;
}

.entry-content {
    line-height: 1.9;
}
 
nav.navbar-scrolled li.nav-item.lang-link a {
    color: #b1aca9!important;
}
 
li.nav-item.lang-link.active a, nav.navbar-scrolled li.nav-item.lang-link.active a  {
    color: #f4623a !important;
}
li.nav-item.lang-link a {
    border-left: 1px solid #d6d2d0;
}
li.no-border.nav-item.lang-link a {
    border: none!important;
}
@media (max-width: 992px) {
li.nav-item.lang-link a {
    border-left: none;
}
}