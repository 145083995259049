.member-box {
    max-width: 400px;
    margin: 50px auto 0;
    @media (min-width: 992px) {
        margin: 20px auto 0;
    }
    p {
        margin-bottom: 0;
    }
    img {
      width: 80%;
      height: 80%;
      object-fit: cover;
      overflow: hidden;
      border-radius: 50%;
    }
}
